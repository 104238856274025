@import '../../Styles/settings';

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdownButton {
  @include HelveticaMedium;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: $midnight-blue;

  height: 40px;
  padding: 10px 16px;
  cursor: pointer;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;

  border: 1px solid $bona-border-grey;
  border-radius: 4px;
  box-shadow: 0 4px 20px 0 rgba(95, 99, 104, 0.08);
  background: #fff;

  &.dropdownButtonOpen {
    border: 1px solid $light-blue;
  }
}

.icon {
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease;

  &.iconOpen {
    transform: rotate(180deg);
  }
}

.dropdownContent {
  display: block;
  min-width: 150px;
  max-width: 400px;
  width: max-content;
  position: absolute;
  margin-top: 5px;
  z-index: 1;

  background: #fff;
  box-shadow: 0 4px 20px 0 rgba(95, 99, 104, 0.08);
  border: 1px solid $light-gray;
  border-radius: 4px;
}

.dropdownItem {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: flex-start;
  padding: 14px 15px;

  cursor: pointer;
  box-sizing: border-box;
  white-space: nowrap;

  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: $near-black;

  &:hover {
    background: $lightest-blue;
  }

  &.selectedItem {
    color: $midnight-blue;
  }
}

.dropdownItem input {
  display: none;
}

.checkbox {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  margin-top: 2px;
}

// Mobile part

.mobileDropdown {
  width: 100%;
}

.mobileDropdownButton {
  width: 100%;
  padding: 16px;
  border: none;
  background: none;
  display: flex;
  justify-content: space-between;

  border-bottom: 1px solid $light-gray;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  color: #202124;
  cursor: pointer;
}

.mobileDropdownContent {
  display: flex;
  flex-direction: column;

  .dropdownItemText {
    width: 85%;
  }
}

.mobileDropdownItem {
  position: relative;
  width: 100%;
  padding: 14px 16px;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid $light-gray;

  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  color: #202124;
  cursor: pointer;

  &:hover {
    background: $lightest-blue;
  }

  &.selectedItem {
    color: $midnight-blue;
  }
}

.mobileDropdownItem input {
  display: none;
}

.categoryCount {
  position: absolute;
  right: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  color: #707378;
}

.dropdownItemText {
  white-space: normal;
  overflow-wrap: break-word;
}

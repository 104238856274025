@import '../../Styles/settings';
@import '../../Styles/variables';

.root {
  position: relative;
  padding-bottom: 40px;

  &.sizeSmall {
    padding-bottom: 0;
  }
  &.noContent {
    height: 0;
    padding: 0;
  }
}

.rootVideoNoTextContent {
  padding-top: 200px;
  padding-bottom: 0;
}

.bgContainer {
  background-size: cover;
}

.img {
  margin: 0 auto;
}

.btn {
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
}

.btnContainer {
  display: flex;
  flex-wrap: wrap;
  padding-top: 16px;
  text-shadow: none;
  width: 100%;
}

.textContainerNoImg {
  padding-top: 40px;
}

.mobileHero {
  position: relative;
}

.mobileHeroImgWrapper {
  position: relative;
  &:after {
    content: '';
    display: block;
    padding-top: 114.32896064581232%;
  }
}

.heroPicture {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.mobileHeroGradient {
  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(
      180deg,
      transparent 50%,
      rgba(0, 0, 0, 0.5) 70%,
      rgba(0, 0, 0, 0.8)
    );
  }
}

.mobileHeroContent {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 15px 20px;
  color: white;
}

.mobileHeroContentNoImage {
  position: relative;
  text-shadow: none;
  color: $text-color;
  padding: 30px 0 20px;
  max-width: 770px;
  margin: 0 auto;
}

.mobileHeadingHeroImg,
.mobileHeroTextHeroImg {
  font-weight: 500;
}

.regionDropdownContainer {
  position: absolute;
  bottom: 0;
  right: 7.5px;
  padding-bottom: 40px;
  &.onVideo {
    left: 70%;
    right: 0;
  }
}

.heroFormDropdownContainer {
  width: 320px;
  position: absolute;
  bottom: 0;
  right: 7.5px;
  padding-bottom: 40px;
  &.onVideo {
    left: 65%;
    right: 0;
  }
  &.smallHeroVideo {
    top: 60%;
  }
}

@media #{$sm-up} {
  .bgContainer:not(.noContentEdit) {
    margin-left: 0;
  }

  .textContainer {
    max-width: 770px;
    margin: 0 auto;
  }

  .videoWrapper {
    top: 50%;
    position: absolute;
    display: block;
    width: 100%;
    transform: translateY(-50%);
    &:before {
      padding-top: 42.857143%;
      display: block;
      content: '';
    }
  }

  .root {
    &.rootVideo {
      padding: 42.857143% 0 0 0;
      height: auto;
      max-height: 392px;
      overflow: hidden;
    }
    &.rootVideoWithForm {
      overflow: unset;
    }
  }
}

@media #{$md-up} {
  .root {
    padding-bottom: 0px;
    height: 392px;
    &.sizeSmall {
      height: 400px;
      padding-top: 0;
    }
    &.noContent {
      height: $standard-header-height-md-up;
    }
  }

  .textContainer {
    max-width: 60%;
    margin: 0 auto;
  }

  .bgContainer:not(.noContentEdit) {
  }

  .heading {
    font-size: 64px;
  }

  .textContainerAbsoluteVideo {
    position: absolute;
    bottom: 0;
    padding-bottom: 40px;
    text-shadow: 1px 2px 2px #000000;
    color: white;
  }

  .textContainerNoImg {
    padding-top: 60px;
    padding-bottom: 40px;
    color: black;
  }

  .p {
    font-size: calc(1rem + 2px);
  }
}

@include media($screen-md, ($content-container-width - 1)) {
  .bgContainer:not(.noContentEdit) {
    margin-left: 0px;
  }
}

@include media($content-container-width) {
  .textContainer {
    padding-left: 0;
    padding-right: 0;
    max-width: 770px;
  }

  .root {
    &.rootVideo {
      margin-left: calc((1158px - 100vw) / 2 - 15px);
      margin-right: calc((1158px - 100vw) / 2 - 15px);
      height: calc(100vw * 9 / 21);
      padding: 0;
    }
  }
}

@include media($app-max-width) {
  .bgContainer:not(.noContentEdit) {
    margin-left: auto;
    margin-right: auto;
  }
}

// NEW design

.hero-block {
  --content-inner-spacing: 24px;
  --content-inner-fixed-width: 100%;
  --container-fixed-height: 100%;
  --content-flex-basis: 100%;
  --content-position: relative;
  --content-align-inner: flex-start;
  --content-title-size: 24px;
  --content-title-line-height: 28px;
  --content-text-size: 16px;
  --content-text-line-height: 20px;
  --flex-direction: column;
  --mobile-img-height: 220px;

  @media #{$sm-up} {
    --mobile-img-height: 391px;
  }

  @media #{$md-up} {
    --content-flex-basis: 43%;
    --content-inner-fixed-width: 37%;
    --container-fixed-height: 492px;
    --content-position: absolute;
    --content-title-size: 36px;
    --content-title-line-height: 40px;
    --content-text-size: 18px;
    --content-text-line-height: 24px;
    --flex-direction: row;
  }

  @media #{$lg-up} {
    --content-flex-basis: 45%;
    --content-inner-spacing: 0;
  }

  @media #{$xl-up} {
    --content-inner-fixed-width: 36%;
    --content-flex-basis: 48%;
    --content-inner-spacing: 0;
  }

  display: flex;
  flex-direction: var(--flex-direction);
  justify-content: space-between;
  width: 100%;
  height: var(--container-fixed-height);
  position: relative;
  color: $white;

  &--has-no-text {
    height: 692px;
  }

  &--has-only-text {
    --container-fixed-height: 166px;
  }

  &__content {
    background-color: $bona-dark-blue;
    flex: 1 0 var(--content-flex-basis);
    height: 100%;
  }

  &__inner-content {
    max-width: $content-container-width;
    margin: 0 auto;
    position: var(--content-position);
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: var(--content-align-inner);
    flex-direction: column;
    justify-content: center;
    padding: var(--content-inner-spacing);
    z-index: map-get($zIndexes, 'heroContent');

    &--fixed-width {
      width: var(--content-inner-fixed-width);
    }
  }

  &__title {
    color: currentColor;
    font-size: var(--content-title-size);
    line-height: var(--content-title-line-height);
    margin-bottom: 16px;
  }

  &__text {
    font-size: var(--content-text-size);
    line-height: var(--content-text-line-height);
    color: currentColor;
    margin-bottom: 40px;
  }

  &__background {
    position: relative;
    flex: 1 0 var(--content-flex-basis);
    height: 100%;
    background-color: $bona-dark-blue;

    & picture {
      height: 100%;
      position: absolute;

      & img {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__mobile-img-wrapper {
    width: 100%;
    height: var(--mobile-img-height);

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__vector-stripes {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: map-get($zIndexes, 'heroVector');
  }
}

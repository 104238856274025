@import "../../Styles/settings";

.filterGroupContainer {
  display: flex;
  flex-direction: column;

  margin-bottom: 24px;
}

.dropdownContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.filterButtonsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 16px;
}

.filterButton {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  border-radius: 4px;
  border: none;
  padding: 6px 10px;
  height: 30px;
  background: $midnight-blue;
  transition: background 0.2s ease;

  font-weight: 500;
  font-size: 14px;
  line-height: 129%;
  text-align: center;
  color: #fff;

  cursor: pointer;

  &:hover {
    background: $darkmidnight_blue;
  }

  .filterIcon {
    display: flex;
    align-items: center;
  }
}

.clearFiltersButton {
  @include HelveticaMedium;
  border: none;
  background: none;
  cursor: pointer;

  font-weight: 500;
  font-size: 16px;
  line-height: 125%;
  color: $midnight-blue;

  &:hover {
    text-decoration: underline;
  }
}

.referenceCases {
  margin-top: 32px;

  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  color: $darkest-gray;
}

// Mobile part

.mobileFilterButton {
  @include HelveticaMedium;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: $midnight-blue;

  height: 40px;
  padding: 10px 16px;
  cursor: pointer;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;

  border: 1px solid $bona-border-grey;
  border-radius: 4px;
  box-shadow: 0 4px 20px 0 rgba(95, 99, 104, 0.08);
  background: #fff;

  &.filterButtonOpen {
    border: 1px solid $light-blue;
  }
}

.icon {
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease;

  &.iconOpen {
    transform: rotate(180deg);
  }
}

@import "../../../Styles/settings";

.mobileModal {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: map-get($zIndexes, "mobileMenuContent");
  overflow: hidden;
  background: #fff;

  display: flex;
  flex-direction: column;
}

.slideIn {
  animation: slideIn 0.3s ease-out;
}

.slideOut {
  animation: slideOut 0.3s ease-out;
}

.modalHeader {
  @include HelveticaMedium;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: #202124;

  position: fixed;
  top: 0;
  width: 100%;
  padding: 24px 10px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-bottom: 1px solid #dee1e6;

  & .modalCloseBtn {
    display: flex;
    align-items: center;

    position: absolute;
    padding: 6px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
  }
}

.modalContent {
  flex: 1;
  margin-top: 80px;
  margin-bottom: 60px;
  overflow-y: auto;
}

.modalFooter {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 16px 16px 24px 16px;
  height: 80px;
  background: #fff;
  display: flex;
  gap: 8px;
}

.clearButton,
.applyButton {
  @include HelveticaMedium;
  width: 50%;
  border-radius: 4px;
  padding: 12px 16px;
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  line-height: 114%;
  text-align: center;
  border: none;
  cursor: pointer;
}

.applyButton {
  background: $midnight-blue;
  color: #fff;
  transition: background-color 0.2s;

  &:hover {
    background: $darkmidnight_blue;
  }
}

.clearButton {
  &.disabled {
    background: #f1f3f4;
    color: #babcbe;
    cursor: not-allowed;
  }

  &.active {
    background: #fff;
    color: #00205c;
    box-shadow: 0 4px 20px 0 rgba(95, 99, 104, 0.08);
  }

  &.active:hover {
    background: #f1f3f4;
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@import '../../Styles/settings';

.root {
  width: 90%;
  max-width: 570px;
  padding: 20px;
  max-height: 90%;
  overflow-y: auto;
  background-color: $color-modal-bg;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  border-radius: 25px;
}

.closeBtn {
  position: absolute;
  top: -12px;
  right: -20px;
}

.closeSvg {
  height: 20px;
  width: 20px;
}

.btnSection {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.contentContainer {
  position: relative;
}

@media #{$sm-up} {
  .root {
  width: 100%;
  max-width: 570px;
  padding: 35px 60px;
  max-height: 90%;
  overflow-y: auto;
  background-color: $color-modal-bg;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  border-radius: 25px;
  }

  .closeBtn {
    position: absolute;
    top: -15px;
    right: -55px;
  }
}

@import '../../Styles/settings';

.root {
  background: #f2f0ea;
  padding: 30px 7px;
  box-shadow: 0px 4px 20px rgba(95, 99, 104, 0.08);
  border-radius: 8px;
}

.rootNoContent {
  max-width: 1158px;
  margin: auto;
  background: white;
}

.container {
  background: white;
  padding: 16px;
  padding-bottom: 24px;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(95, 99, 104, 0.08);
  z-index: map-get($zIndexes, 'heroContent');

  &.noBg {
    background: transparent;
  }
  &.noContent {
    background: #f2f0ea;
    max-width: 264px;
    margin-left: auto;
  }
  &.onlyBg {
    position: absolute;
    max-width: 264px;
    right: 18px;
    bottom: 0;
  }
}

.title {
  font-size: 16px;
  color: $color-brand;
  margin-bottom: 7px;
}

.text {
  font-size: 12px;

  margin-bottom: 0;
  color: $dark-gray;
}

.arrowUp {
  width: 18px;
  height: 10px;
  margin-left: 12px;
  animation: none;
  transform: rotate(180deg);
}

.arrowDown {
  width: 18px;
  height: 10px;
  margin-left: 12px;
  animation: none;
}

.regionSelector {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  &:hover {
    cursor: pointer;
  }
}

.selectList {
  width: 100%;
  background: none;
  margin-left: -3px;
  border: none;
  font-size: 16px;
  padding: 12px;
  border: 1px solid #babcbe;
  border-radius: 4px;
}

.dropdownContainer {
  color: $dark-gray;
  // header dropdown
  > div {
    width: 100%;
    filter: none;
    border: 1px solid $bona-dark-grey;
    padding: 12px 12px;

    span {
      color: $dark-gray;
    }
  }

  svg path {
    stroke: $dark-gray;
  }

  // list dropdown
  ul {
    top: 52px;
    width: 100%;
  }
}

@media #{$md-up} {
  .root {
    max-width: 264px;
    padding: 0 0;
  }

  .container {
    margin-bottom: 18px;
  }

  .ctaBtn {
    margin-bottom: 0;
  }

  .selectList {
    font-size: 14px;
  }
}

@import '../../Styles/settings';

.root {
    background: #f2f0ea;
    padding: 32px 8px;
    box-shadow: 0px 4px 20px rgba(95, 99, 104, 0.08);
    border-radius: 8px;
    
}

.rootNoContent {
    max-width: 1128px;
    margin: auto;
    background: white;
}

.iframe {
    max-width: 100%;
    border: 0;
    display: block;
}

.container {
    border-radius: 8px;
    background: white;
    padding: 16px;
    padding-bottom: 24px;
    z-index: map-get($zIndexes, 'heroFormDropdown');
    &.noBg {
        background: transparent;
    }
    &.noContent {
        background: #f2f0ea;
        max-width: 320px;
        margin-left: auto;
    }
    &.onlyBg {
        position: absolute;
        max-width: 320px;
        right: 10%;
        bottom: 0;
    }
    &.smallHeroImage {
        position: absolute;
        max-width: 320px;
        right: 10%;
        top: 60%;
        height: fit-content;
    }
    &.borderAround {
        border: 4px solid #00205C;
    }
    &.noPaddingBottom {
        padding-bottom: 0;
    }
}

.title {
    font-size: 16px;
    color: #00205C;
    margin-bottom: 7px;
}

.contentContainer {
    max-width: 770px;
    margin: 0 auto;
}

.text {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 16px;
}

.subscribeArea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-size: 14px;
}

.arrowUp {
    width: 18px;
    height: 10px;
    margin-left: 12px;
    animation: none;
    transform: rotate(180deg);
  }
  
  .arrowDown {
    width: 18px;
    height: 10px;
    margin-left: 12px;
    animation: none;
  }

  .formWrapper {
      position: relative;
  }

  .cancel {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 34px;
      cursor: pointer;
  }

  @media #{$xs-only} {
    .contentContainer{
      width: 100%;
      max-width: 600px;
      margin: 0 auto;
    }
  }

  @media #{$md-up} {
    .root {
        padding: 0 0;
        width: 320px;
    }

    .container {
        margin-bottom: 18px;
    }

    .ctaBtn {
        margin-bottom: 0;
    }
  }